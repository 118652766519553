<template>
  <div class="metricas">
    <div class="metricas-right">
      <div class="metricas-top">
        <Icon name="chevron_left" style="fontSize: 2rem;" :click="() => $router.push('/')" class="metricas-back" pointer/>
        <Icon name="arrow_back" style="fontSize: 1.5rem;" color="fff" :click="() => $router.push('/')" class="metricas-back-mobile" pointer/>
        <p class="metricas-title">Métricas Mi Basf</p>
        <label class="metricas-subtitle"
          >Monitoreando visitas desde 1 Julio 2022</label
        >
      </div>
      <div class="metricas-container">
        <div class="metrica-left">
          <div class="metrica-row">
            <label class="metrica-label">Visitas totales Mi Basf</label>
            <label class="metrica-data-blue">{{ total }} Visitas</label>
          </div>
          <div class="metrica-row">
            <label class="metrica-label">Ingresos únicos distintos</label>
            <label class="metrica-data-blue">{{ totalUnico }} Visitas</label>
          </div>
          <div class="metrica-left-sub">
            <div>
              <div class="metrica-row">
                <label class="metrica-label">Diario (hoy)</label>
                <label class="metrica-data-grey">{{ diario }} Visitas</label>
              </div>
              <div class="metrica-row">
                <label class="metrica-label">Semanal</label>
                <label class="metrica-data-grey">{{ semanal }} Visitas</label>
              </div>
              <div class="metrica-row">
                <label class="metrica-label">Mensual</label>
                <label class="metrica-data-grey">{{ mensual }} Visitas</label>
              </div>
            </div>
            <div>
              <div class="metrica-row">
                <label class="metrica-label">Diario unicos (hoy)</label>
                <label class="metrica-data-grey"
                  >{{ diarioUnico }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-label">Semanal únicos</label>
                <label class="metrica-data-grey"
                  >{{ semanalUnico }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-label">Mensual únicos</label>
                <label class="metrica-data-grey"
                  >{{ mensualUnico }} Visitas</label
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="metrica-top">
            <label class="metrica-label">Visitas totales Secciones</label>
          </div>
          <div class="metrica-chart-container">
            <div class="metrica-row-porcentaje app-mobile-no">
              <div class="metrica-visitas-container">
                <div>
                  <label class="metrica-porcentaje" style="color: #66c409"
                    >{{ porcentajeMiAlta }}%</label
                  >
                </div>
                <div>
                  <label class="metrica-porcentaje" style="color: #21a0d2"
                    >{{ porcentajeMiCuentaYOrden }}%</label
                  >
                </div>
                <div>
                  <label class="metrica-porcentaje" style="color: #ffaa00"
                    >{{ porcentajeMiCuentaCorriente }}%</label
                  >
                </div>
                <div>
                  <label class="metrica-porcentaje" style="color: #004a96"
                    >{{ porcentajeMiPOG }}%</label
                  >
                </div>
                <div>
                  <label class="metrica-porcentaje" style="color: #585858"
                    >{{ porcentajeMiPlan }}%</label
                  >
                </div>
                <div>
                  <label class="metrica-porcentaje" style="color: #8AC3A0"
                    >{{ porcentajeMisPedidos }}%</label
                  >
                </div>
              </div>
              <div class="metrica-visitas-container">
                <div>
                  <label class="metrica-label">Mi Alta</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMiAlta }} Visitas</label
                  >
                </div>
                <div>
                  <label class="metrica-label">Mi Cuenta y Orden</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMiCuentaYOrden }} Visitas</label
                  >
                </div>
                <div>
                  <label class="metrica-label">Mi Cuenta Corriente</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMiCuentaCorriente }} Visitas</label
                  >
                </div>
                <div>
                  <label class="metrica-label">Mi POG</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMiPOG }} Visitas</label
                  >
                </div>
                <div>
                  <label class="metrica-label">Mi Plan Comercial</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMiPlan }} Visitas</label
                  >
                </div>
                <div>
                  <label class="metrica-label">Mis Pedidos</label>
                  <label class="metrica-data-grey"
                    >{{ visitasMisPedidos }} Visitas</label
                  >
                </div>
              </div>
            </div>
            <div class="metrica-row-porcentaje app-solo-mobile">
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #66c409"
                  >{{ porcentajeMiAlta }}%</label
                >
                <label class="metrica-label">Mi Alta</label>
                <label class="metrica-data-grey"
                  >{{ visitasMiAlta }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #21a0d2"
                  >{{ porcentajeMiCuentaYOrden }}%</label
                >
                <label class="metrica-label">Mi Cuenta y Orden</label>
                <label class="metrica-data-grey"
                  >{{ visitasMiCuentaYOrden }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #ffaa00"
                  >{{ porcentajeMiCuentaCorriente }}%</label
                >
                <label class="metrica-label">Mi Cuenta Corriente</label>
                <label class="metrica-data-grey"
                  >{{ visitasMiCuentaCorriente }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #004a96"
                  >{{ porcentajeMiPOG }}%</label
                >
                <label class="metrica-label">Mi POG</label>
                <label class="metrica-data-grey"
                  >{{ visitasMiPOG }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #585858"
                  >{{ porcentajeMiPlan }}%</label
                >
                <label class="metrica-label">Mi Plan Comercial</label>
                <label class="metrica-data-grey"
                  >{{ visitasMiPlan }} Visitas</label
                >
              </div>
              <div class="metrica-row">
                <label class="metrica-porcentaje" style="color: #8AC3A0"
                  >{{ porcentajeMiPlan }}%</label
                >
                <label class="metrica-label">Mis Pedidos</label>
                <label class="metrica-data-grey"
                  >{{ visitasMisPedidos }} Visitas</label
                >
              </div>
            </div>
            <div class="metricas-chart">
              <Pie
                ref="pieChart"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
                hide-legend
              />
            </div>
          </div>
        </div>
      </div>
      <div class="metricas-registros">
        <div class="metricas-registros-top">
          <label>Registro de visita</label>
          <Dropdown
            :options="registroVisitas"
            :placeholder="'Hoy'"
            :paraArriba="false"
            :conInput="false"
            v-on:updateOption="updateDropdownRegistro"
          ></Dropdown>
          <div class="metricas-descarga-container">
            <Datepicker
              ref="desde"
              referencia="desde"
              :customPlaceholder="'Siempre'"
            ></Datepicker>
            <label>▶</label>
            <Datepicker
              ref="hasta"
              referencia="hasta"
              :customPlaceholder="'Hoy'"
            ></Datepicker>
            <button class="metricas-descarga-btn" @click="descargar()">
              Descargar Excel
            </button>
          </div>
          <Icon name="search" style="fontSize: 1.5rem;" :click="abrirBuscar" class="metricas-registros-buscar-img" pointer/>
          <input
            v-show="mostrarBuscar"
            class="metricas-registros-buscar-input"
            type="text"
            placeholder="Buscar"
            ref="buscar"
            v-model="buscarText"
            @keyup="buscar()"
          />
        </div>
        <div class="metricas-registros-container">
          <div
            v-for="metrica in metricasFiltradas"
            :key="metrica.id"
            class="metricas-registro"
          >
            <label>{{ getFecha(metrica.fecha) }}</label>
            <label>{{ getHora(metrica.fecha) }}</label>
            <label>{{ getModulo(metrica.modulo) }}</label>
            <label class="metricas-registro-email">{{
              metrica.usuario.email
            }}</label>
            <label>{{ getRol(metrica.modulo, metrica.usuario) }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Pie } from "vue-chartjs/legacy";
import Icon from "@/components/Icon.vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
import XLSX from "xlsx";

export default {
  name: "metricas",
  components: { Pie, Dropdown, Datepicker, Icon },
  data() {
    return {
      metricas: [],
      metricasModulos: [],
      metricasFiltradas: [],
      chartId: "pie-chart",
      datasetIdKey: "label",
      width: 100,
      height: 100,
      cssClasses: "",
      styles: {},
      plugins: [],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#004A96",
              "#FFAA00",
              "#21A0D2",
              "#66C409",
              "#585858",
              "#8AC3A0",
            ],
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
      },
      total: "-",
      totalUnico: "-",
      diario: "-",
      diarioUnico: "-",
      semanal: "-",
      semanalUnico: "-",
      mensual: "-",
      mensualUnico: "-",
      visitasMiAlta: "-",
      visitasMiCuentaYOrden: "-",
      visitasMiCuentaCorriente: "-",
      visitasMiPOG: "-",
      visitasMiPlan: "-",
      visitasMisPedidos: "-",
      porcentajeMiAlta: "-",
      porcentajeMiCuentaYOrden: "-",
      porcentajeMiCuentaCorriente: "-",
      porcentajeMiPOG: "-",
      porcentajeMiPlan: "-",
      porcentajeMisPedidos: "-",
      registroVisitas: [
        {
          name: "Hoy",
        },
        {
          name: "Semanal",
        },
        {
          name: "Mensual",
        },
      ],
      mostrarBuscar: false,
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      buscarText: "",
      registro: null,
    };
  },
  created() {},
  mounted() {
    this.getMetricas();
    this.getMetricasModulos();
    this.getMetricasTotales();
  },
  methods: {
    getMetricas() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/metricas")
        .then(function (response) {
          console.log(response);
          that.metricas = response.data;
          that.calcularMetricas();
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    getMetricasModulos() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/metricas/modulos")
        .then(function (response) {
          let hoy = new Date();
          that.metricasModulos = response.data;
          that.metricasFiltradas = response.data.filter(
            (m) =>
              m.modulo != "micuenta" &&
              new Date(m.fecha).getDate() == hoy.getDate() &&
              new Date(m.fecha).getMonth() == hoy.getMonth() &&
              new Date(m.fecha).getFullYear() == hoy.getFullYear()
          );
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    calcularMetricas() {
      let hoy = new Date();

      let date = new Date();
      date.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
      // January 4 is always in week 1.
      let week1 = new Date(date.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      let weekNumber =
        2 +
        Math.round(
          ((date.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        );

      this.mensual = this.metricas.length;
      this.mensualUnico = [
        ...new Set(this.metricas.map((m) => m.usuario.id)),
      ].length;

      this.semanal = this.metricas.filter((m) => m.week == weekNumber).length;
      this.semanalUnico = [
        ...new Set(
          this.metricas
            .filter((m) => m.week == weekNumber)
            .map((m) => m.usuario.id)
        ),
      ].length;

      this.diario = this.metricas.filter(
        (m) =>
          new Date(m.fecha).getDate() == hoy.getDate() &&
          new Date(m.fecha).getMonth() == hoy.getMonth() &&
          new Date(m.fecha).getFullYear() == hoy.getFullYear()
      ).length;
      this.diarioUnico = [
        ...new Set(
          this.metricas
            .filter(
              (m) =>
                new Date(m.fecha).getDate() == hoy.getDate() &&
                new Date(m.fecha).getMonth() == hoy.getMonth() &&
                new Date(m.fecha).getFullYear() == hoy.getFullYear()
            )
            .map((m) => m.usuario.id)
        ),
      ].length;
    },
    getMetricasTotales() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/metricas/totales")
        .then(function (response) {
          console.log(response);
          if (response.data != null) {
            that.total = response.data.total;
            that.totalUnico = response.data.totalUnicos;
            that.visitasMiAlta = response.data.totalMiAlta;
            that.visitasMiCuentaYOrden = response.data.totalMiCYO;
            that.visitasMiCuentaCorriente = response.data.totalMiCC;
            that.visitasMiPOG = response.data.totalMiPOG;
            that.visitasMiPlan = response.data.totalMiPlanes;
            that.visitasMisPedidos = response.data.totalMisPedidos;

            let totalSinMiCuenta = response.data.totalSinMiCuenta;

            if (totalSinMiCuenta != 0) {
              that.porcentajeMiAlta =
                Math.round(
                  ((that.visitasMiAlta * 100) / totalSinMiCuenta) * 100
                ) / 100;
              that.porcentajeMiCuentaYOrden =
                Math.round(
                  ((that.visitasMiCuentaYOrden * 100) / totalSinMiCuenta) * 100
                ) / 100;
              that.porcentajeMiCuentaCorriente =
                Math.round(
                  ((that.visitasMiCuentaCorriente * 100) / totalSinMiCuenta) *
                    100
                ) / 100;
              that.porcentajeMiPOG =
                Math.round(
                  ((that.visitasMiPOG * 100) / totalSinMiCuenta) * 100
                ) / 100;
              that.porcentajeMiPlan =
                Math.round(
                  ((that.visitasMiPlan * 100) / totalSinMiCuenta) * 100
                ) / 100;
              that.porcentajeMisPedidos =
                Math.round(
                  ((that.visitasMisPedidos * 100) / totalSinMiCuenta) * 100
                ) / 100;
            } else {
              that.porcentajeMiAlta = 0;
              that.porcentajeMiCuentaYOrden = 0;
              that.porcentajeMiCuentaCorriente = 0;
              that.porcentajeMiPOG = 0;
              that.porcentajeMiPlan = 0;
              that.porcentajeMisPedidos = 0;
            }

            that.chartData.datasets[0].data = [
              that.porcentajeMiPOG,
              that.porcentajeMiCuentaCorriente,
              that.porcentajeMiCuentaYOrden,
              that.porcentajeMiAlta,
              that.porcentajeMiPlan,
              that.porcentajeMisPedidos
            ];
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    updateDropdownRegistro(payload) {
      this.registro = payload;
      this.filtrar();
    },
    filtrar() {
      this.buscarText = "";
      if (this.registro.name == "Hoy") {
        let hoy = new Date();
        this.metricasFiltradas = this.metricasModulos.filter(
          (m) =>
            m.modulo != "micuenta" &&
            new Date(m.fecha).getDate() == hoy.getDate() &&
            new Date(m.fecha).getMonth() == hoy.getMonth() &&
            new Date(m.fecha).getFullYear() == hoy.getFullYear()
        );
      } else if (this.registro.name == "Semanal") {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
        // January 4 is always in week 1.
        let week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        let weekNumber =
          2 +
          Math.round(
            ((date.getTime() - week1.getTime()) / 86400000 -
              3 +
              ((week1.getDay() + 6) % 7)) /
              7
          );
        this.metricasFiltradas = this.metricasModulos.filter(
          (m) => m.week == weekNumber
        );
      } else if (this.registro.name == "Mensual") {
        this.metricasFiltradas = this.metricasModulos;
      }
    },
    getFecha(fecha) {
      let d = new Date(fecha);
      d.setHours(d.getHours() - 3);
      return (
        d.getDate() + " " + this.meses[d.getMonth()] + " " + d.getFullYear()
      );
    },
    getHora(fecha) {
      let d = new Date(fecha);
      d.setHours(d.getHours() - 3);
      let hora = d.getHours().toString();
      if (hora.length == 1) {
        hora = "0" + hora;
      }
      let minuto = d.getMinutes().toString();
      if (minuto.length == 1) {
        minuto = "0" + minuto;
      }
      return hora + ":" + minuto;
    },
    getModulo(modulo) {
      if (modulo == "mialta") {
        return "Mi Alta";
      } else if (modulo == "pog") {
        return "Mi POG";
      } else if (modulo == "cc") {
        return "Mi Cuenta Corriente";
      } else if (modulo == "cyo") {
        return "Mi Cuenta y Orden";
      } else if (modulo == "planes") {
        return "Mi Plan Comercial";
      } else if (modulo == "mispedidos") {
        return "Mis Pedidos";
      }
    },
    getRol(modulo, usuario) {
      let retorno = "-";
      switch (modulo) {
        case "mialta":
          retorno =
            usuario.rolClientes != null ? usuario.rolClientes.detalle : "-";
          break;
        case "pog":
          retorno = usuario.rolPOG != null ? usuario.rolPOG.detalle : "-";
          break;
        case "cc":
          retorno = usuario.rolCC != null ? usuario.rolCC.detalle : "-";
          break;
        case "cyo":
          retorno = usuario.rolCYO != null ? usuario.rolCYO.detalle : "-";
          break;
        case "mispedidos":
          retorno = usuario.rolPedidos != null ? usuario.rolPedidos.detalle : "-";
          break;
      }
      return retorno;
    },
    abrirBuscar() {
      this.mostrarBuscar = true;
      setTimeout(() => {
        this.$refs.buscar.focus();
      }, 150);
    },
    buscar() {
      const that = this;
      let filtroBuscar = this.buscarText.toLowerCase();
      this.metricasFiltradas = this.metricasModulos.filter(
        (m) =>
          that.getModulo(m.modulo).toLowerCase().indexOf(filtroBuscar) > -1 ||
          m.usuario.email.toLowerCase().indexOf(filtroBuscar) > -1 ||
          that.getRol(m.modulo, m.usuario).toLowerCase().indexOf(filtroBuscar) >
            -1
      );
    },
    descargar() {
      let array = [];
      const desde = this.$refs.desde.time1;
      let hasta = this.$refs.hasta.time1;
      if (hasta != "") {
        hasta.setHours(23);
        hasta.setMinutes(59);
        hasta.setSeconds(59);
      }
      const json = {
        desde: desde,
        hasta: hasta,
      };
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/metricas/modulos", json)
        .then(function (response) {
          response.data.forEach((m) => {
            let d = new Date(m.fecha);
            d.setHours(d.getHours() - 6);
            array.push({
              fecha: d.toJSON().split("T").join(" ").substr(0, 16),
              modulo: m.modulo,
              semana: m.week,
              email: m.usuario.email,
              rol: that.getRol(m.modulo, m.usuario),
            });
          });
          let data = XLSX.utils.json_to_sheet(array);
          data["A1"].v = "Fecha";
          data["B1"].v = "Módulo";
          data["C1"].v = "Semana";
          data["D1"].v = "Email";
          data["E1"].v = "Rol CC";
          const workbook = XLSX.utils.book_new();
          const filename = "Métricas";
          XLSX.utils.book_append_sheet(workbook, data, filename);
          XLSX.writeFile(workbook, `${filename}.xlsx`);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped src="../assets/css/views/metricas.css"></style>
